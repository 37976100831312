<template>
	<div>
		<el-drawer
			title="我是标题"
			:visible.sync="drawer"
			direction="rtl"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>新增合同</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
					<el-form-item label="" prop="contractTitle">
						<div class="inputtitle" style="margin-top: 0px;">
							<span>*</span>
							合同标题
						</div>
						<div class="inputbox"><el-input v-model="ruleForm.contractTitle" placeholder="请输入合同标题"></el-input></div>
					</el-form-item>

					<el-form-item label="" prop="amount">
						<div class="inputtitle">
							<span>*</span>
							合同金额
						</div>
						<div class="inputboxyuan">
							<el-input v-model="ruleForm.amount" placeholder="请输入合同金额"></el-input>
							<span class="yuan">元</span>
						</div>
					</el-form-item>
					
					<el-form-item label="" prop="signTime">
						<div class="inputtitle" style="margin-top: 30px;">
							<span>*</span>
							签约时间
						</div>
						<div class="choosetime">
							<el-date-picker
								v-model="ruleForm.signTime"
								value-format="yyyy-MM-dd hh:mm:ss"
								type="datetime"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>
					
					<el-form-item label="" prop="endTime">
						<div class="inputtitle" style="margin-top: 30px;">
							<span>*</span>
							截止时间
						</div>
						<div class="choosetime">
							<el-date-picker
								v-model="ruleForm.endTime"
								value-format="yyyy-MM-dd hh:mm:ss"
								type="datetime"
								placeholder="选择日期"
							></el-date-picker>
						</div>
					</el-form-item>

					<el-form-item label="" prop="contractTypeId">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							合同类型
						</div>
						<div class="allway">
							<div
								class="oneway"
								v-for="(item, index) in contractType"
								@click="chooseContractType(item)"
								:key="item.settingId"
							>
								<div :class="{ activeway: item.settingId === ruleForm.contractTypeId }">
									<p v-if="item.settingId === ruleForm.contractTypeId"></p>
								</div>
								<span>{{ item.settingName }}</span>
							</div>
						</div>
					</el-form-item>

					<el-form-item label="" prop="payWayId">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							付款方式
						</div>
						<div class="allway">
							<div
								class="oneway"
								v-for="(item, index) in payType"
								@click="choosePayWayId(item)"
								:key="item.settingId"
							>
								<div :class="{ activeway: item.settingId === ruleForm.payWayId }">
									<p v-if="item.settingId === ruleForm.payWayId"></p>
								</div>
								<span>{{ item.settingName }}</span>
							</div>
						</div>
					</el-form-item>

					<el-form-item label="" prop="belongUserId">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							执行人员
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.belongUserId" placeholder="请选择执行人员" filterable>
								<el-option
									v-for="item in employeesList"
									:key="item.userId"
									:value="item.userId"
									:label="item.realName"
								></el-option>
							</el-select>
						</div>
					</el-form-item>

					<el-form-item label="" prop="projectId">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							关联项目
						</div>
						<div class="inputbox">
							<el-select v-model="ruleForm.projectId" placeholder="请选择项目" filterable>
								<el-option
									v-for="item in projectLists"
									:key="item.projectId"
									:value="item.projectId"
									:label="item.projectName"
								></el-option>
							</el-select>
						</div>
					</el-form-item>

					<el-form-item label="" prop="offerDocPath" style="margin-bottom: 0;">
						<div class="inputtitle">
							<span>*</span>
							报价方案
						</div>
						<el-upload
							class="upload-demo"
							ref="upload"
							:action="$baseUrl + '/common/uploadFile'"
							:show-file-list="false"
							name="file"
							:on-success="baojiaUpFileSuccess"
						>
							<div slot="tip" class="el-upload__tip">
								上传文件类型：jpeg/jpg/png/mp4/xlsx/xls/doc/docx/pdf
							</div>
							<div class="upbox nochoosetext" style="">
								<i class="el-icon-plus"></i>
								<span>点击上传</span>
							</div>
						</el-upload>
						<!-- <template v-for="(item, index) in ruleForm.docList"> -->
						<div class="box1" v-if="ruleForm.offerDocPath">
							<div class="box2">
								<img src="@/assets/customer/image5.png" />
								<span>{{ ruleForm.offerDocName }}</span>
							</div>
							<img class="deleteicon" src="@/assets/customer/image4.png" @click="deleteDoc" />
						</div>
						<!-- </template> -->
					</el-form-item>
				</el-form>
				<div class="twobtn">
					<div @click="resetForm()">取消</div>
					<div class="save" @click="submitForm('ruleForm')">保存</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { addContract, allUserList, projectList } from '@/network/api.js';
export default {
	data() {
		return {
			projectLists: [], // 项目列表
			employeesList: [], // 所有员工列表
			ruleForm: {
				amount: '', //合同金额
				belongUserId: '', //归属人id(不选默认自己)
				contractTitle: '', //合同标题
				contractTypeId: '', //合同类型id
				endTime: '', //截止时间
				offerDocName: '', //报价方案文档名称
				offerDocPath: '', //报价方案文档路径
				payWayId: '', //付款方式id
				projectId: '', //项目id
				signTime: '' //签约时间
			},
			rules: {
				amount: [{ required: true, message: '请输入合同金额', trigger: 'blur' }],
				belongUserId: [{ required: true, message: '请选择归属人', trigger: 'change' }],
				// contractId: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
				contractTitle: [{ required: true, message: '请输入合同标题', trigger: 'change' }],
				contractTypeId: [{ required: true, message: '请选择合同类型', trigger: 'change' }],
				endTime: [{ required: true, message: '请选择截止时间', trigger: 'change' }],
				// offerDocName: [{ required: true, message: '请输入报价方案文档名称', trigger: 'blur' }],
				offerDocPath: [{ required: true, message: '请输入报价方案文档路径', trigger: 'change' }],
				payWayId: [{ required: true, message: '请选择付款方式', trigger: 'change' }],
				projectId: [{ required: true, message: '请选择项目', trigger: 'change' }],
				signTime: [{ required: true, message: '请选择签约时间', trigger: 'change' }]
			},
			drawer: false,
		};
	},
	computed: {
		// ...mapState(['companyTypeList', 'cluesSourceList','companyIndustryList'])
		...mapState('systemModule', {
			contractType: 'contractType',
			payType: 'payType'
		})
	},
	created() {
		this._allUserList();
		this._projectList();
	},
	methods: {
		// 选择合同类型
		chooseContractType(item) {
			this.ruleForm.contractTypeId = item.settingId
		},
		// 选择付款方式
		choosePayWayId(item) {
			this.ruleForm.payWayId = item.settingId
		},
		// 获取我负责的项目列表
		async _projectList() {
			const res = await projectList({
				companyIndustry: '', //所属行业
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 1000000,
				projectName: '',
				searchBody: ''
			});
			console.log('我负责的项目列表', res);
			this.projectLists = res.data.list;
		},
		// 获取所有员工列表
		async _allUserList() {
			const res = await allUserList();
			this.employeesList = res.data;
		},
		// 点击删除上传的文件
		deleteDoc() {
			this.ruleForm.offerDocName = '';
			this.ruleForm.offerDocPath = '';
		},
		// 上传报价文件成功回调 设计方案上传
		baojiaUpFileSuccess(info, file) {
			this.ruleForm.offerDocName = file.name;
			this.ruleForm.offerDocPath = info.data.fileUrl;
		},
		// 添加合同
		async _addContract() {
			await addContract(this.ruleForm);
			this.$myMessage('添加成功', 'success');
			this.$emit('updataInfo')
			this.resetForm()
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this._addContract()
				} else {
					this.$myMessage('请将信息填写完整', 'error');
					return false;
				}
			});
		},
		resetForm() {
			this.$refs['ruleForm'].resetFields();
			this.drawer = false;
		},
		// 选择跟进方式
		chooseWay(index) {
			this.wayIndex = index;
		},
	
	}
};
</script>

<style scoped lang="scss">
	.deleteicon {
		cursor: pointer;
		width: 16px;
		height: 16px;
	}
	.box2 {
		display: flex;
		align-items: center;
		img {
			width: 18px;
			height: 17px;
			margin: 0 12px 0 0;
		}
		span {
			font-size: 14px;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #3286ff;
		}
	}
	.box1:hover {
		background-color: #faf9fb;
	}
	.box1 {
		margin: 0 0 15px 17px;
		width: 598px;
		height: 34px;
		justify-content: space-between;
		display: flex;
		align-items: center;
		padding: 0 20px 0 12px;
	}
	.upbox:active {
		opacity: 0.6;
	}
	.upbox {
		cursor: pointer;
		margin: 0 0 0 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 108px;
		height: 32px;
		background: rgba(46, 119, 246, 0.08);
		border-radius: 4px;
		border: 1px solid #2e77f6;
		i {
			color: #3286ff;
		}
		span {
			font-size: 0.875em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #3286ff;
			margin: 0 0 0 1em;
		}
	}
	

::v-deep {
	.yuan {
		margin: 0 0 0 20px;
	}
	.inputboxyuan {
		display: flex;
		align-items: center;
		.el-input {
			width: 550px !important;
			height: 40px !important;
		}
		.el-input__inner {
			width: 550px !important;
			height: 40px !important;
		}
	}
	.inputbox {
		.el-input__inner {
			width: 600px !important;
			height: 40px !important;
		}
	}

	.el-form-item {
		margin: 0;
	}
}
::v-deep {
	.choosetime {
		.el-input__inner {
			width: 400px !important;
			height: 40px !important;
		}
	}
}

.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 30px 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #f22e18;
		margin: 0 10px 0 0;
	}
}
.oneway {
	cursor: pointer;
	margin: 0 30px 10px 0;
	display: flex;
	align-items: center;
	div {
		width: 14px;
		height: 14px;
		background: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px 0 0;
		&.activeway {
			border: 1px solid #2e77f6;
		}
		p {
			width: 8px;
			background-color: #2e78f6;
			height: 8px;
			border-radius: 50%;
		}
	}
}
.allway {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0 0 17px;
}
::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}
textarea:focus {
	border: 1px solid #4d91ff;
}
textarea {
	font-size: 14px;
	outline: none;
	padding: 13px 20px;
	width: 100%;
	height: 90px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	max-width: 100%;
	min-width: 100%;
	min-height: 50px;
}
.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
