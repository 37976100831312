<template>
	<div class="gonghai">
		<div class="top1">
			<div></div>
			<span>合同管理</span>
		</div>

		<div class="tablebox">
			<div class="tabletop">
				<div class="inputbox">
					<input placeholder="关键词搜索" v-model="info.searchBody"/>
					<div class="flex"><i @click="_contractList" class="el-icon-search" style="color: #AAAAAA;"></i></div>
				</div>
				<div class="selectbox">
					<span>合同类型</span>
					<el-select v-model="info.contractTypeId" placeholder="请选择">
						<el-option
							v-for="item in contractType"
							:key="item.settingId"
							:label="item.settingName"
							:value="item.settingId"
						></el-option>
					</el-select>
				</div>
		<!-- 		<div class="selectbox">
					<span>开始日期</span>
					<el-date-picker v-model="value1" type="date" placeholder="选择日期"></el-date-picker>
				</div>
				<div class="selectbox">
					<span>结束日期</span>
					<el-date-picker v-model="value1" type="date" placeholder="选择日期"></el-date-picker>
				</div> -->

				<div class="chaxun nochoosetext flex" style="margin-left: 2.5em;" @click="_contractList"><span>查询</span></div>
				<div class="chaxun nochoosetext flex" @click="clickReset"><span>重置</span></div>
							
				<div class="addbox11" @click="showAdd">
					<i class="el-icon-plus" style="color: #2F79FF;"></i>
					<span>新增</span>
				</div>
			</div>
			
			<div class="allcountbox">
				共筛选出 <span style="color: #3266F7;">{{totalCount}}</span> 条合同记录
			</div>

			<div style="padding: 1.25em;">
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column type="index" label="序号"></el-table-column>
					<!-- <el-table-column prop="name" label="合同编号"></el-table-column> -->
					<el-table-column prop="contractTitle" label="标题"></el-table-column>
					<el-table-column prop="projectName" label="关联项目"></el-table-column>
					<el-table-column prop="amount" label="合同金额(元)"></el-table-column>
					<el-table-column prop="contractTypeName" label="合同类型"></el-table-column>
					<el-table-column prop="signTime" label="签单时间"></el-table-column>
					<el-table-column prop="payWayName" label="付款方式"></el-table-column>
					<el-table-column prop="belongUserName" label="归属人"></el-table-column>
					<el-table-column prop="createdTime" label="添加时间"></el-table-column>
					<el-table-column prop="overtime" label="附件">
						<template slot-scope="scope">
							<div class="hetongtext">
								{{scope.row.offerDocName}}
							</div>
						</template>
					</el-table-column>

					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<div class="twobtn">
					

								<div class="kanbox gai" @click="showEdit(scope.row)">
									<img src="../../assets/btnicon/image3.png" />
									改
								</div>

								<div class="kanbox shan" @click="showDetele(scope.row.contractId)">
									<img src="../../assets/btnicon/image6.png" />
									删
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>

				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="info.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="info.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>

		<edit-contract ref="editContract" @updataInfo="_contractList"></edit-contract>
		<add-contract ref="addContract" @updataInfo="_contractList"></add-contract>
		<delete-popup ref="deletePopup" @deleteInfo="_deleteContract" message="是否删除此条合同信息"></delete-popup>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import {settingList,contractList,deleteContract} from "@/network/api.js"
import editContract from "./popup/editContract.vue"
import addContract from "./popup/addContract.vue"
import deletePopup from "../../components/common/deletePopup.vue"
export default {
	data() {
		return {
			deleteId: '',
			totalCount: 0,
			companyList: [
				{
					value: '阿里',
					lable: '阿里'
				}
			],
			info: {
				contractTypeId: '',   // 合同类型id
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				searchBody: '',
			},
			company: '',
			tableData: []
		};
	},
	components: {
		editContract,
		addContract,
		deletePopup
	},
	name: 'contractManage',
	computed: {
		...mapState('systemModule', {
			contractType: 'contractType',
			payType: 'payType'
		})
	},
	created() {
		// this._settingList()
		this._contractList()
	},
	methods: {
		// 点击重置
		clickReset() {
			this.info.contractTypeId = ''
			this.info.searchBody = ''
		},
		// 删除合同
		async _deleteContract() {
			await deleteContract({
				contractId: this.deleteId
			})
			this.$myMessage('删除成功','success')
			this.$refs.deletePopup.dialogVisible = false
			this._contractList()
		},
		// 获取合同列表
		async _contractList() {
			const res = await contractList(this.info)
			this.tableData = res.data.list
			console.log("合同列表",res);
			this.totalCount = res.data.totalCount
		},
		// 获取设置列表
		async _settingList() {
			const res = await settingList({settingType: 7})
			console.log(res);
		},
		// 点击出现删除弹框
		showDetele(id) {
			this.$refs.deletePopup.dialogVisible = true
			this.deleteId = id
		},
		// 点击出现修改弹框
		showEdit(info) {
			this.$refs.editContract.drawer = true
			this.$refs.editContract.ruleForm = {
				amount: info.amount, //合同金额
				belongUserId: info.belongUserId, //归属人id(不选默认自己)
				contractTitle: info.contractTitle, //合同标题
				contractTypeId: info.contractTypeId, //合同类型id
				endTime: info.endTime, //截止时间
				offerDocName: info.offerDocName, //报价方案文档名称
				offerDocPath: info.offerDocPath, //报价方案文档路径
				payWayId: info.payWayId, //付款方式id
				projectId: info.projectId, //项目id
				signTime: info.signTime,//签约时间
				contractId: info.contractId,
			}
		},
		// 点击出现添加弹框
		showAdd() {
			this.$refs.addContract.drawer = true
		},
		handleSizeChange(val) {
			this.info.pageSize = val
			this._contractList()
		},
		handleCurrentChange(val) {
			this.info.page = val
			this._contractList()
		}
	}
};
</script>

<style scoped lang="scss">
	.allcountbox {
		width: 100%;
		background-color: #EFF3F6;
		height: 3.25em;
		padding: 0 1.25em;
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5B6271;
		display: flex;
		align-items: center;
		span {
			margin: 0 0.3em;
		}
	}
	.hetongtext {
		cursor: pointer;
		font-size: 0.875em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #2E77F6;
	}
.top1 {
	width: 100%;
	height: 3.25em;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding: 0 1.0625em;
	div {
		width: 0.1875em;
		height: 1.125em;
		background: linear-gradient(270deg, #0463ec 0%, #3286ff 100%);
		border-radius: 0.125em;
	}
	span {
		font-size: 1.125em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		margin: 0 0 0 0.625em;
		color: #303030;
	}
}
::v-deep {
	.el-form-item__content {
		line-height: 20px;
	}
	.pagebox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: 6.25em 0 0 1.25em;
		padding: 0 1.25em 0 0;
		.el-input__inner {
			color: #666666;
			width: 3em;
			height: 2.25em;
			line-height: 2.25em;
		}
		.el-select {
			.el-input__inner {
				color: #666666;
				width: 8em;
				height: 2.25em;
				line-height: 2.25em;
			}
		}

		.el-input__icon {
			line-height: 2.25em;
		}
	}
}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 0.9375em;
		height: 1.0625em;
	}
	&.shan {
		background-color: #b3bcc9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.gen {
		background-color: #fa7e33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #3dc57c;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.875em;
		}
	}
	&.zeng {
		background-color: #9f7eff;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.8125em;
		}
	}
	img {
		width: 1em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox11:active {
	opacity: 0.6;
}
.addbox11 {
	border: 1px solid #2F79FF;
	margin: 0 0 0 1.25em;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background-color: rgb(234,242,254);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875em;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2F79FF;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chaxun:active {
	background-color: rgb(234,242,255);
	color: #2F79FF;
	border: 1px solid #2F79FF;
}
.chaxun {
	cursor: pointer;
	margin: 0 0 0 1.25em;
	width: 5.625em;
	height: 2.25em;
	border-radius: 0.25em;
	border: 1px solid #DDDDDD;
	font-weight: 400;
	color: #333333;
	font-family: PingFang-SC-Medium, PingFang-SC;
	span {
		font-size: 0.875em;
	}
}
.tabletop {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 1em 1.25em 1.25em;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
::v-deep {
	.selectbox {
		margin: 0 0 0 2.5em;
		display: flex;
		align-items: center;
		// overflow: hidden;
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			margin: 0 0.6875em 0 0;
		}
		.el-input__inner {
			color: #666666;
			width: 9.75em;
			height: 2.25em;
			line-height: 2.25em;
		}
	}
}

.tablebox {
	margin: 0.9375em 0 0 0;
	background-color: #ffffff;
	
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		cursor: pointer;
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}

.gonghai {
	padding: 0.9375em;
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
